/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    var Site = {

        init: function () {
            this.mobile = 767;
            this.tablet = 1024;
            this.controller = new ScrollMagic.Controller();
        },

        displaySubMenu: function () {
            var $menu = $('.menu-venue-hire');
            var $link = $menu.children('a');

            $menu.on('mouseenter', function () {
                $(this).children('.sub-menu').show();
            });

            $menu.on('mouseleave', function () {
                $(this).children('.sub-menu').hide();
            });
        },

        stickyNav: function () {
            var $header = $('header.banner');

            // Pin the header menu
            new ScrollMagic.Scene({
                triggerElement: $header[0],
                triggerHook: 0
            })
                .setPin($header[0], {pushFollowers: false})
                .addTo(this.controller);
        },

        smoothScroll: function () {
            var $menu = $('header.banner');
            var $menuItems = $menu.find('nav a');
            var offset = -$menu.height();

            $menuItems.smoothScroll({
                offset: offset
            });
        },

        mobileMenu: function () {
            var $hamburger = $('.hamburger-menu');
            var $navItems = $('nav a');

            if (window.innerWidth > Site.tablet) {
                return false;
            }

            $hamburger.on('click', function () {
                $hamburger.siblings('nav, .social').toggleClass('active');
                $hamburger.parent().toggleClass('active');
                $('.wrap').toggle();
            });

            $navItems.on('click', function (event) {
                event.preventDefault();
                var url = $(this).attr('href');

                // Hide the menu if the url contains an anchor link
                if (url.indexOf('#') !== -1) {
                    $hamburger.siblings('nav, .social').removeClass('active');
                    $hamburger.parent().removeClass('active');
                    $('.wrap').css('display', 'block');
                }
                window.location = url;
            });
        },

        rearrangeMobile: function () {
            if (window.innerWidth <= Site.mobile) {
                var $main = $('main.main');
                var $hero = $('section#hero');
                var $bookings = $('section#bookings');
                var $whatsOn = $('section#whats-on');
                var $menu = $('section#menu');
                var $gallery = $('section#gallery');
                var $neonSign = $('section#neon-sign');
                var $ourPlace = $('section#our-place');
                var $contact = $('section#contact');


                var $nav = $('nav ul.nav');
                var $menuHome = $('li.menu-home');
                var $menuBookings = $('li.menu-bookings');
                var $menuWhatsOn = $('li.menu-whats-on');
                var $menuMenu = $('li.menu-menu');
                var $menuGallery = $('li.menu-gallery');
                var $menuOurPlace = $('li.menu-our-place');
                var $menuContact = $('li.menu-contact');

                $contact.prependTo($main);
                $neonSign.prependTo($main);
                $gallery.prependTo($main);
                $menu.prependTo($main);
                $whatsOn.prependTo($main);
                $bookings.prependTo($main);
                $ourPlace.prependTo($main);
                $hero.prependTo($main);

                $menuContact.prependTo($nav);
                $menuGallery.prependTo($nav);
                $menuMenu.prependTo($nav);
                $menuWhatsOn.prependTo($nav);
                $menuBookings.prependTo($nav);
                $menuOurPlace.prependTo($nav);
                $menuHome.prependTo($nav);
            }
        },

        selectSection: function () {
            var $sections = $('section');
            var $menuItems = $('nav li a');

            if (window.innerWidth <= Site.tablet) {
                return false;
            }

            // Get the id for each section, associated menu item
            // and add a class to the menu item when the section
            // is in viewport
            $sections.each(function (index, element) {
                var $section = $(element);
                var sectionId = '#' + $section.attr('id');
                var $sectionMenuItem;

                // Find the nav menu item matching the section
                $menuItems.each(function (index, element) {
                    var $menuItem = $(element);
                    var anchor = $menuItem.attr('href');

                    if (anchor.indexOf(sectionId) !== -1) {
                        $sectionMenuItem = $menuItem;
                    }
                });

                if ($sectionMenuItem === undefined) {
                    return;
                }

                // Add a hook for sections with valid nav menus
                new ScrollMagic.Scene({
                    triggerElement: sectionId,
                    triggerHook: 0.5,
                    duration: $section.height()
                })
                    .setClassToggle($sectionMenuItem[0], 'selected')
                    .addTo(Site.controller);
            });
        },

        carouselBookings: function () {
            var $images = $('.bookings-image');
            var index = 0;

            $images.first().fadeIn();

            setInterval(function () {
                $images.eq(index).fadeIn();
                $images.eq(index).siblings('.bookings-image').fadeOut();

                index += 1;

                if (index === $images.length) {
                    index = 0;
                }
            }, 5000);
        },

        scrollEvents: function () {
            var $eventsContainer = $('.events');
            var $events = $('.event');
            var $eventsTooltip = $('.events-tooltip');
            var $prevEvents = $('.events-previous');
            var $nextEvents = $('.events-next');
            var visibleEvents = 5;

            // Hide tooltip
            $eventsTooltip.add($events).one('mouseover', function () {
                $eventsTooltip.fadeOut();
            });

            // Set number of events visible for different screen sizes
            if (window.innerWidth <= Site.mobile) {
                visibleEvents = 2;
            } else if (window.innerWidth <= Site.tablet) {
                visibleEvents = 4;
            }

            // Show first n elements in list
            $events.slice(0, visibleEvents).addClass('event-selected');

            // Snow next events button
            if ($events.length > visibleEvents) {
                $nextEvents.show();
            }

            $nextEvents.on('click', function () {
                var $selectedElements = $('.event-selected');

                // Show the previous event arrow
                if ($selectedElements.first().index() !== 0) {
                    $prevEvents.show();
                }

                // Disable the next event arrow
                if ($selectedElements.last().index() > $events.length) {
                    $nextEvents.hide();
                }

                // Hide the first visible event
                $selectedElements.first().removeClass('event-selected');

                // Show the next event
                $selectedElements.next().addClass('event-selected');
            });

            $prevEvents.on('click', function () {
                var $selectedElements = $('.event-selected');

                // Disable previous event arrow
                if ($selectedElements.first().index() <= 4) { // Exclude the tooltip and arrows
                    $prevEvents.hide();
                }

                // Show the next event arrow
                if ($selectedElements.last().index() < $events.length + 3) {
                    $nextEvents.show();
                }

                // Hide the last visible event
                $selectedElements.last().removeClass('event-selected');

                // Show the previous event
                $selectedElements.prev().addClass('event-selected');
            });
        },

        displayFeaturedEvent: function () {
            var $events = $('.event');
            var $featured = $('.featured-event');

            $featured.html($events.first().children().clone());

            $events.on('click', function () {
                var $event = $(this);
                $featured.html($event.children().clone());
            });
        },

        displayGalleryItem: function () {
            var $gallery = $('.gallery-images');
            var $galleryItems = $('.gallery-image');
            var $overlay = $('.gallery-overlay');
            var $featuredImage = $('.gallery-featured-image');

            if (window.innerWidth <= Site.mobile) {
                return false;
            }

            $galleryItems.on('click', function () {
                var image = $(this).css('background-image');
                $overlay.show();
                $featuredImage.css('background-image', image).show();
            });

            $featuredImage.add($overlay).on('click', function () {
                $featuredImage.hide();
                $overlay.hide();
            });
        },

        flashNeon: function () {
            var $neonOff = $('.neon-off');

            if ($neonOff.length === 0) {
                return false;
            }

            (function flicker() {
                var rand = Math.floor(Math.random() * 3000);
                setTimeout(function () {
                    $neonOff.show();
                    $neonOff.delay(200).hide(0);
                    flicker();
                }, rand);
            }());
        },

        transitionGallery: function () {
            var $gallery = $('#venue-gallery .container');
            var $images = $gallery.children('.venue-image');
            var $sourceImages = $images.clone();
            var maxElements = 5;

            if ($gallery.length === 0) {
                return false;
            }

            // Only show the first 15 images, so there's a few hidden images that will be used
            // in the transition that aren't displayed immediately
            $images.slice(0, maxElements).addClass('venue-image-visible');

            setInterval(function () {
                var $firstImage = $($images[Math.floor(Math.random() * $images.length)]);
                var $secondImage = $($images[Math.floor(Math.random() * $images.length)]);
                var firstImageUrl = $firstImage.css('background-image');
                var secondImageUrl = $secondImage.css('background-image');

                $firstImage.children('.venue-image-overlay').css('background-image', firstImageUrl).show();
                $secondImage.children('.venue-image-overlay').css('background-image', secondImageUrl).show();

                $firstImage.css('background-image', secondImageUrl);
                $secondImage.css('background-image', firstImageUrl);

                $firstImage.children('.venue-image-overlay').fadeOut(1000);
                $secondImage.children('.venue-image-overlay').fadeOut(1000);

            }, 3000);
        }
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                Site.init();
                Site.displaySubMenu();
                Site.stickyNav();
                Site.mobileMenu();
                Site.rearrangeMobile();
                Site.transitionGallery();
                Site.smoothScroll();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                Site.selectSection();
                Site.carouselBookings();
                Site.scrollEvents();
                Site.displayFeaturedEvent();
                Site.displayGalleryItem();
                Site.flashNeon();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
